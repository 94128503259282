import React, { Fragment, useEffect, useState } from 'react';
import { LazyLoadTypes } from 'react-slick';
import 'swiper/css';
import 'swiper/css/pagination';
import { ModalBox } from '../ModalBox';
import { ISliderProps, ISlide } from './types';
import {
  SliderWrapper,
  CarouselInner,
  CorpContentSliderWrapper,
  CarouselHeading,
  Caption,
  CarouselContentWrapper,
  StyledPlayCorpContentSlider,
  VideoSlideContent,
  ContentContainer,
  ContentSection,
  ImageSection,
} from './styles';

import { Title, Text } from '../Typography';
import { MetaBallContainer } from '../MetaBallContainer';
import { Picture } from '../../modules/responsivePicture';
import { getPictureSources } from '../../modules/getPictureSources';
import GetSafari from '../../modules/getSafari';

import { CorporateButton } from 'components/CorporateButton';
import Link from 'next/link';
import { Heading } from 'components/CorpPressStoriesCard/styles';
import { SeeMoreCardArrow } from 'components/Icons/icons';
import clsx from 'clsx';
import { CorpCardCarouselVariant } from 'generated/graphql';
import { Slider } from 'components/Slider';

export const CorpContentSlider = ({
  slides = [],
  heading,
  title,
  backgroundImg,
  bottomLeftAsset,
  topRightAsset,
  slidesToShow = 1,
  cardVariant,
}: ISliderProps) => {
  const [showLink, setShowLink] = useState(false);

  useEffect(() => {
    setShowLink(true);
  }, []);

  const CardVariant = cardVariant ?? CorpCardCarouselVariant.neutral;

  const shouAddRightPadding = slidesToShow === slides.length;

  const calculateSlidesPerView = () => {
    if (slidesToShow === 1) {
      if (slides.length === 1) return 1;
      if (slides.length > 1) return 1.5;
    } else if (slidesToShow >= 2) {
      if (slidesToShow < slides.length) {
        return slidesToShow + 0.5;
      }
      return slidesToShow;
    }
    return slidesToShow;
  };

  const sliderSettings = {
    lazyLoad: 'ondemand' as LazyLoadTypes,
    infinite: false,
    arrows: true,
    isStaticArrows: true,
    centeredSlides:
      slides.length === 1 ||
      (slidesToShow === 1 && CardVariant !== CorpCardCarouselVariant.primary),
    adaptiveHeight: false,
    slidesPerView:
      slides.length === 1
        ? 1
        : CardVariant === CorpCardCarouselVariant.primary
        ? 3.5
        : calculateSlidesPerView(),
    speed: 500,
    spaceBetween: 10,
    slidesToScroll: 1,
    className: 'mySwiper',
  };

  const slideType = (
    sliderType: string,
    image?: Record<string, string>,
    videoId?: string,
    link?: {
      url: string;
      displayName: string;
      openInNewTab?: boolean;
    },
  ) => {
    const RenderImageWithLink = () => {
      if (CardVariant === CorpCardCarouselVariant.neutral) {
        return (
          <div className="overlay-container h-300 sm:h-350 md:h-500 lg:h-full">
            <Picture sources={getPictureSources(image)} alt="" className="min-h-[500px]" />
            {link && (
              <div className="overlay">
                <Link href={link?.url ?? '/'} target={link?.openInNewTab ? '_blank' : '_self'}>
                  <CorporateButton
                    label={link?.displayName}
                    type="button"
                    buttonType="button"
                    color="primary"
                  />
                </Link>
              </div>
            )}
          </div>
        );
      }

      return <Picture sources={getPictureSources(image)} alt="" />;
    };

    switch (sliderType) {
      case 'video':
        return (
          <div className="overlay-container">
            <VideoSlideContent>
              <StyledPlayCorpContentSlider>
                <ModalBox videoID={videoId} />
              </StyledPlayCorpContentSlider>
              <Picture sources={getPictureSources(image)} alt="" />
            </VideoSlideContent>
          </div>
        );
      case 'image':
        return <RenderImageWithLink />;
      default:
        return <RenderImageWithLink />;
    }
  };

  const isSafari = GetSafari();
  const backgroundImage = isSafari ? backgroundImg?.backgroundFallback : backgroundImg?.background;

  return (
    <MetaBallContainer
      data-test="content-slider-metaball-container"
      topRightImage={topRightAsset?.url}
      bottomLeftImage={bottomLeftAsset?.url}
    >
      <CorpContentSliderWrapper
        data-test="content-slider-content-slider-wrapper"
        style={{ backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined }}
      >
        <CarouselHeading data-test="content-slider-carousel-heading">
          {heading && (
            <Text type="sm" tag="p" className="uppercase pb-8">
              {heading}
            </Text>
          )}
          {title && (
            <Title type="sm" tag="h3" className="mb-6">
              {title}
            </Title>
          )}
        </CarouselHeading>
        <SliderWrapper
          data-test="content-slider-slider-wrapper"
          className="z-10"
          cardVariant={CardVariant}
        >
          <Slider
            enableDots={CardVariant !== CorpCardCarouselVariant.neutral && true}
            isHiddenOnMobile={true}
            data-test="content-slider-slider"
            {...sliderSettings}
            className={clsx('md:pl-8 lg:pl-16', shouAddRightPadding && 'md:pr-16')}
          >
            {slides &&
              slides.length > 0 &&
              slides.map(({ link, videoId, caption, slideAsset, id }: ISlide) => (
                <Fragment key={id}>
                  {CardVariant === CorpCardCarouselVariant.neutral && (
                    <CarouselContentWrapper data-test="content-slider-carousel-content-wrapper">
                      <CarouselInner
                        data-test="content-slider-carousel-inner"
                        className="lg:h-heroInner text-center md:h-300 px-4 md:px-0 sm:h-heroInnerS overflow-hidden relative"
                      >
                        {videoId
                          ? slideType('video', slideAsset, videoId, link)
                          : slideType('image', slideAsset, undefined, link)}
                      </CarouselInner>
                      <div className="max-w-screen-md">
                        {caption && <Caption data-test="content-slider-caption">{caption}</Caption>}
                      </div>
                    </CarouselContentWrapper>
                  )}
                  {CardVariant === CorpCardCarouselVariant.primary && (
                    <div className="px-4 md:px-0 pb-12 carousel-card h-full" key={id}>
                      <ContentContainer
                        className={clsx({
                          'md:min-h-[580px]': CardVariant === CorpCardCarouselVariant.primary,
                        })}
                      >
                        <ImageSection cardVariant={CardVariant}>
                          {videoId
                            ? slideType('video', slideAsset, videoId, link)
                            : slideType('image', slideAsset, undefined, link)}
                        </ImageSection>
                        <ContentSection>
                          {caption && (
                            <Heading tag="div" type="xs">
                              <Caption data-test="content-slider-caption">{caption}</Caption>
                            </Heading>
                          )}
                        </ContentSection>
                        {showLink && link && (
                          <Link
                            href={link?.url ?? ''}
                            target={link?.openInNewTab ? '_blank' : '_self'}
                            className="flex mx-auto justify-start items-center pb-5 cursor-pointer mb-10"
                          >
                            <Text tag="p" type="l" className="uppercase pr-2">
                              {link?.displayName}
                            </Text>
                            <SeeMoreCardArrow />
                          </Link>
                        )}
                      </ContentContainer>
                    </div>
                  )}
                </Fragment>
              ))}
          </Slider>
        </SliderWrapper>
      </CorpContentSliderWrapper>
    </MetaBallContainer>
  );
};

CorpContentSlider.defaultProps = {
  slidesToShow: 1,
};
